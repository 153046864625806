<div class="collection-container">
    <div
        *ngFor="let item of inputValue; let i = index; trackBy: trackByIndex"
        [@fadeIn]="!!item.__created"
        [class.dim]="fade === i"
        [class.single]="singleLine"
        [class.card]="!singleLine"
        [class.collapsed]="!singleLine && collapsable && !expanded.has(i) && selectedTask && item.id !== selectedTask"
        [class.highlight]="selectedTask && item.id === selectedTask"
        class="collection-rows"
    >
        <hr class="separator" />

        <div
            [id]="item.id"
            class="card-header d-flex py-1 px-1 px-lg-1 d-print-none align-items-center task-header"
            *ngIf="!singleLine"
            (click)="$event.stopPropagation(); toggleExpanded(i)"
        >
            <i class="text-danger fa fa-exclamation-triangle me-2" *ngIf="childrenValid.includes(i) && !itemForm.touched"></i>

            <div class="complete-toggle">
                <button
                    class="btn btn-success btn-sm me-2 px-1"
                    [disabled]="view.vars.disabled"
                    *ngIf="inputValue[i].complete"
                    (click)="$event.stopPropagation(); toggleComplete(i)"
                    ngbTooltip="Mark Task Incomplete"
                >
                    <i class="fa fa-fw fa-check-square-o"></i>
                </button>
                <button
                    class="btn btn-light btn-sm me-2 px-1"
                    [disabled]="view.vars.disabled"
                    *ngIf="!inputValue[i].complete"
                    (click)="$event.stopPropagation(); toggleComplete(i)"
                    ngbTooltip="Mark Task Complete"
                >
                    <i class="fa fa-fw fa-square-o"></i>
                </button>
            </div>

            <ngxh6-usericon class="me-2" size="small" [user]="inputValue[i].allocatedUser" *ngIf="inputValue[i].allocatedUser"></ngxh6-usericon>

            <p class="m-0 text-nowrap">
                <strong class="me-2" *ngIf="item?.type?.string">{{ item.type?.string }}:</strong> {{ item[descriptionField] }}
            </p>
            <div class="flex-shrink-0 ms-auto d-flex align-items-center">
                <ng-container [ngTemplateOutlet]="actions"></ng-container>
            </div>
        </div>

        <form
            [class.small]="small"
            #itemForm="ngForm"
            ngForm
            (click)="!!(collapsable && !expanded.has(i) && toggleExpanded(i)); (null)"
            [@collapse]="{ value: expanded.has(i) || (selectedTask && item.id === selectedTask) || !collapsable, params: { collapsed_height: 0 } }"
        >
            <ng-container *ngIf="isCompleteOffline(item.id) || !offlineService.isConnected">
                <div class="alert alert-success" *ngIf="completeTasks.has(item.id)">
                    <div class="offline btn btn-sm btn-success ms-2 float-right" (click)="completeOffline(item.id)">Edit</div>
                    Task completed offline. Data will update on next sync.
                </div>

                <div class="alert alert-warning" *ngIf="!completeTasks.has(item.id) && !offlineService.isConnected">
                    <div class="offline btn btn-sm btn-primary ms-2 float-right" *ngIf="canCompleteOffline(item)" (click)="completeOffline(item.id)">
                        Complete Offline
                    </div>
                    You're currently offline. Functionality is limited.
                </div>
            </ng-container>

            <div *ngIf="!singleValue" [ngClass]="!singleLine ? 'card-body p-2 p-lg-3' : 'flex-grow-1'">
                <sf-form
                    #sfFormComponent
                    [view]="$any(view.children)[i] || view?.vars?.prototype"
                    name="value"
                    [(ngModel)]="inputValue[i]"
                    [renderAll]="true"
                    (ngModelChange)="onInputChange()"
                    [formClasses]="'collection-row row'"
                    [horizontal]="horizontal"
                    [wide]="wide"
                ></sf-form>
            </div>

            <div class="row flex-grow-1" *ngIf="singleValue">
                <sf-widget
                    rowClasses="col-12"
                    [view]="$any(view.children)[i] || view?.vars?.prototype"
                    name="value"
                    [(ngModel)]="inputValue[i]"
                    (ngModelChange)="onInputChange()"
                    [horizontal]="false"
                ></sf-widget>
            </div>

            <div *ngIf="singleLine" class="d-flex flex-column">
                <label class="form-label invisible">.</label>
                <ng-container [ngTemplateOutlet]="actions"></ng-container>
            </div>
        </form>

        <ng-template #actions>
            <div class="btn btn-sm btn-gray" *ngIf="item.id" (click)="$event.stopPropagation(); modalService.history(item.id)" [class.d-none]="singleLine">
                <i class="fa fa-history"></i>
            </div>
            <div class="btn btn-sm ms-2 btn-gray expand" *ngIf="collapsable" (click)="$event.stopPropagation(); toggleExpanded(i)" [class.d-none]="singleLine">
                <i class="fa" [class.fa-expand]="!expanded.has(i)" [class.fa-compress]="expanded.has(i)"></i>
            </div>
            <div
                class="btn ms-2 btn-danger collection-remove"
                *ngIf="view?.vars?.allow_delete || item?.__created"
                (click)="$event.stopPropagation(); removeItem(i)"
                (mouseover)="fade = i"
                (mouseleave)="fade = null"
                [class.btn-sm]="!singleLine"
            >
                <i class="fa fa-trash"></i>
            </div>
        </ng-template>
    </div>
</div>

<div class="d-flex" *ngIf="view?.vars?.allow_add">
    <button type="button" class="btn btn-gray mt-1 mb-0" (click)="addItem()"><i class="fa fa-plus"></i> {{ view?.vars?.attr?.add_label || 'Add' }}</button>
</div>
