<div class="content-container m-0 login-container">
    <div class="d-flex my-4">
        <div class="login-form mx-auto">
            <form (ngSubmit)="doSignin()" #signinForm="ngForm">
                <div class="d-flex mb-3 flex-column">
                    <img class="logo p-4" src="/assets/logo-large.svg" alt="App Logo" />
                </div>

                <div class="alert alert-danger" role="alert" *ngIf="error">Error: {{ error }}</div>

                <div class="form-group">
                    <label class="form-label" for="signin_email">Email or Username</label>
                    <input class="form-control" type="text" id="signin_email" name="email" required [(ngModel)]="signin.email" autocomplete="username" />
                </div>

                <div class="form-group">
                    <label class="form-label" for="signin_pass">Password</label>
                    <input
                        class="form-control"
                        type="password"
                        id="signin_pass"
                        name="password"
                        required
                        [(ngModel)]="signin.password"
                        autocomplete="current-password"
                    />
                </div>

                <div class="d-flex">
                    <input type="submit" value="Sign In" class="btn btn-primary ms-0 mb-4" [disabled]="!signinForm.form.valid || loading" />
                </div>

                <!--        <p class="my-0 small"><a routerLink="/password-reset">Forgot Password?</a></p>-->
            </form>
        </div>
    </div>
</div>
